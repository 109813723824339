(function () {
    angular.module('informaApp')
        .component('trendsViewElement', {
            templateUrl: 'components/summary-view/summary-elements/elements/trends-view-element/template.html',
            controller: TrendsViewElement,
            bindings: {
                data: '<',
                settings: '<',
                hiddenPhases: '<',
                tableGroupOptions: '<',
                hiddenDrugs: '<',
                onReady: '<',
                onStartRedraw: '<'
            }
        });

    function TrendsViewElement(TrendsViewMapper, ObjectPropertyReader, ConstantsSvc) {
        const onReceiveChartData = ([chartData]) => {
            this.chartData = chartData;

            this.onReady();
        };

        this.chartOptions = getChartOptions();

        const defaultRange = ConstantsSvc.trendsView.yearRanges[0];

        this.$onChanges = (changes) => {
            const range = ObjectPropertyReader.getPropertyOrDefault(this.settings, x => x.range, defaultRange);

            const isRangeChanged = changes.settings && changes.settings.currentValue.range !== changes.settings.previousValue.range;

            this.metrics = this.settings.metrics;

            this.inverted = this.settings.inverted;
            this.chartOptions = getChartOptions(this.inverted);

            if (this.data && isRangeChanged) {
                this.onStartRedraw();

                TrendsViewMapper.map([this.data], range, this.hiddenDrugs).then(onReceiveChartData);
            } else {
                onReceiveChartData([this.chartData]);
            }
        };

        this.onInvertedChanged = () => {
            this.chartOptions = getChartOptions(this.inverted);
        }
    }

    function getChartOptions(inverted) {
        return {
            isLegendAtTheBottom: true,
            inverted
        }
    }
})();